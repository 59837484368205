import { ConfirmDialogComponent } from './../../../../shared/confirm-dialog/confirm-dialog.component';
import { lastValueFrom } from 'rxjs';
import { CustomerSelectorDto, CustomersClient } from './../../../../core/services/api.service';
import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges, inject, output } from '@angular/core';
import { UserAddCustomerModalComponent } from './user-add-customer-modal/user-add-customer-modal.component';
import { MatDialog } from '@angular/material/dialog';

import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'sn-user-customers',
    templateUrl: './user-customers.component.html',
    styleUrls: ['./user-customers.component.scss'],
    imports: [FormsModule, MatButtonModule, MatTooltipModule]
})
export class UserCustomersComponent implements AfterViewInit, OnChanges {
  protected _matDialog = inject(MatDialog);
  private _customersClient = inject(CustomersClient);


  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() primaryCustomerId: number | undefined = undefined;
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() createCustomerIds: number[] | undefined = undefined;
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() viewCustomerIds: number[] | undefined = undefined;
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() billCustomerIds: number[] | undefined = undefined;
  readonly customers = output<any>();

  initialCustomerIds: {primaryCustomerId: number | undefined, createCustomerIds: number[] | undefined, viewCustomerIds: number[] | undefined, billCustomerIds: number[] | undefined} | undefined = undefined;

  private _userCustomers: UserCustomerViewModel[] = [];
  set userCustomers(value: UserCustomerViewModel[]) {
    this._userCustomers = value;
  }

  get userCustomers(): UserCustomerViewModel[] {
    this.sortUserCustomers();
    return this._userCustomers;
  }
  // This will be use to identify if the user has changed the primary customer
  primaryCustomerIdValueChangeCounter = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.createCustomerIds || !this.viewCustomerIds || !this.billCustomerIds) {
      return;
    }

    if (changes.primaryCustomerId && changes.primaryCustomerId.currentValue !== changes.primaryCustomerId.previousValue) {
      this.primaryCustomerId = changes.primaryCustomerId.currentValue;
      this.primaryCustomerIdValueChangeCounter++;
      if (this.primaryCustomerIdValueChangeCounter > 1) {
        this.setNewPrimaryCustomer();
      }
    }
  }

  async ngAfterViewInit() {
    if (!this.createCustomerIds) {
      this.createCustomerIds = [];
    }

    if (!this.viewCustomerIds) {
      this.viewCustomerIds = [];
    }

    if (!this.billCustomerIds) {
      this.billCustomerIds = [];
    }

    await this.getCustomers();
    this.initialCustomerIds = {
      primaryCustomerId: this.primaryCustomerId,
      createCustomerIds: this.createCustomerIds,
      viewCustomerIds: this.viewCustomerIds,
      billCustomerIds: this.billCustomerIds
    };
  }

  showAddCustomerModal() {
    const dialogRef = this._matDialog.open(UserAddCustomerModalComponent, {
      disableClose: true,
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result: CustomerSelectorDto) => {
      if (result) {
        const userCustomer = this.userCustomers.find(x => x.customer?.id === result.id);
        if (!userCustomer) {
          this.userCustomers.push({
            customer: result,
            isPrimary: false,
            isCreateLoad: false,
            isViewLoad: true,
            isBillLoad: false,
            hasNetSuiteId: result.externalCustomerId ? true : false,
          });
          this.emitCustomerIds();
        }
      }
    });
  }

  removeCustomer(userCustomer: UserCustomerViewModel) {
    const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: { title: "Remove Customer", message: "Are you sure you want to remove customer '" + userCustomer.customer?.name + "'?", cancelButtonText: "No", okButtonText: "Yes" },
      disableClose: true
    });

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userCustomers = this.userCustomers.filter(s => s.customer?.id !== userCustomer.customer?.id);
        this.emitCustomerIds();
      }
    })
  }

  async getCustomers() {
    var customerIds = this.gelAllCustomerIds();
    const customers = await lastValueFrom(this._customersClient.customers_GetCustomerSelectorByIds(customerIds))
    for (const customer of customers) {
      const userCustomer = this.userCustomers.find(x => x.customer?.id === customer.id);
      if (userCustomer) {
        userCustomer.isPrimary = this.isCustomerPrimary(customer.id);
        userCustomer.isBillLoad = this.isCustomerHasBillAccess(customer.id);
        userCustomer.isCreateLoad = this.isCustomerHasCreateAccess(customer.id);
        userCustomer.isViewLoad = this.isCustomerHasViewAccess(customer.id);
      } else {
        this.userCustomers.push({
          customer: customer,
          isPrimary: this.isCustomerPrimary(customer.id),
          isCreateLoad: this.isCustomerHasCreateAccess(customer.id),
          isViewLoad: this.isCustomerHasViewAccess(customer.id),
          isBillLoad: this.isCustomerHasBillAccess(customer.id),
          hasNetSuiteId: customer.externalCustomerId ? true : false,
        });
      }
    }
  }

  userCustomerBillLoadToggle(userCustomer: UserCustomerViewModel) {
    userCustomer.isBillLoad = !userCustomer.isBillLoad;
    this.emitCustomerIds();
  }

  userCustomerCreateLoadToggle(userCustomer: UserCustomerViewModel) {
    userCustomer.isCreateLoad = !userCustomer.isCreateLoad;
    userCustomer.isBillLoad = false;
    this.emitCustomerIds();
  }

  userCustomerViewLoadToggle(userCustomer: UserCustomerViewModel) {
    userCustomer.isViewLoad = !userCustomer.isViewLoad;
    this.emitCustomerIds();
  }

  userCustomerPrimaryToggle(userCustomer: UserCustomerViewModel) {
    if (!userCustomer.isPrimary) {
      // check if there is existing primary customer
      const existingPrimaryCustomer = this.userCustomers.find(x => x.isPrimary);
      if (existingPrimaryCustomer) {
        const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
          width: '400px',
          data: { title: "Primary Customer", message: "Are you sure you want to change primary customer to '" + userCustomer.customer?.name + "'?", cancelButtonText: "No", okButtonText: "Yes" },
          disableClose: true
        });

        confirmDialogRef.afterClosed().subscribe(result => {
          if (result) {
            userCustomer.isPrimary = true;
            userCustomer.isBillLoad = true;
            userCustomer.isCreateLoad = true;
            userCustomer.isViewLoad = true;
            existingPrimaryCustomer.isPrimary = false;
          } else {
            userCustomer.isPrimary = false;
          }
          this.emitCustomerIds();
        });
      } else {
        userCustomer.isPrimary = !userCustomer.isPrimary;
      }
    } else {
      userCustomer.isPrimary = !userCustomer.isPrimary;
    }

    if (userCustomer.isPrimary) {
      userCustomer.isBillLoad = true;
      userCustomer.isCreateLoad = true;
      userCustomer.isViewLoad = true;
    }

    this.emitCustomerIds();
  }

  emitCustomerIds() {
    this.createCustomerIds = this.userCustomers.filter(x => x.isCreateLoad).map(x => x.customer?.id ?? 0);
    this.viewCustomerIds = this.userCustomers.filter(x => x.isViewLoad).map(x => x.customer?.id ?? 0);
    this.billCustomerIds = this.userCustomers.filter(x => x.isBillLoad).map(x => x.customer?.id ?? 0);
    this.primaryCustomerId = this.userCustomers.find(x => x.isPrimary)?.customer?.id;

    const hasNoNetSuiteId = this.userCustomers.filter(x => x.isBillLoad).some(x => !x.hasNetSuiteId);

    this.customers.emit({
      primaryCustomerId: this.primaryCustomerId,
      createCustomerIds: this.createCustomerIds,
      viewCustomerIds: this.viewCustomerIds,
      billCustomerIds: this.billCustomerIds,
      hasChanges: hasNoNetSuiteId ? false : this.hasChanges({
        primaryCustomerId: this.primaryCustomerId,
        createCustomerIds: this.createCustomerIds,
        viewCustomerIds: this.viewCustomerIds,
        billCustomerIds: this.billCustomerIds,
      })
    });
  }

  hasChanges(
    newCustomerIds: {
      primaryCustomerId: number | undefined, 
      createCustomerIds: number[] | undefined, 
      viewCustomerIds: number[] | undefined, 
      billCustomerIds: number[] | undefined } | undefined) {
    // check if initital customerids and new Customerds are not the same
    if (!this.initialCustomerIds || !newCustomerIds) {
      return false;
    }

    if (this.initialCustomerIds.primaryCustomerId !== newCustomerIds.primaryCustomerId) {
      return true;
    }

    if (this.initialCustomerIds.createCustomerIds?.length !== newCustomerIds.createCustomerIds?.length) {
      return true;
    }

    if (this.initialCustomerIds.viewCustomerIds?.length !== newCustomerIds.viewCustomerIds?.length) {
      return true;
    }

    if (this.initialCustomerIds.billCustomerIds?.length !== newCustomerIds.billCustomerIds?.length) {
      return true;
    }

    if (this.initialCustomerIds.createCustomerIds?.some(x => !newCustomerIds.createCustomerIds?.includes(x))) {
      return true;
    }

    if (this.initialCustomerIds.viewCustomerIds?.some(x => !newCustomerIds.viewCustomerIds?.includes(x))) {
      return true;
    }

    if (this.initialCustomerIds.billCustomerIds?.some(x => !newCustomerIds.billCustomerIds?.includes(x))) {
      return true;
    }

    return false;
  }

  private setNewPrimaryCustomer() {
    if (this.primaryCustomerId) {
      const userCustomer = this.userCustomers.find(x => x.customer?.id === this.primaryCustomerId);
      if (userCustomer) {
        userCustomer.isViewLoad = true;
        userCustomer.isPrimary = true;
        userCustomer.isCreateLoad = true;
        userCustomer.isBillLoad = true;
      } else {
        this._customersClient.customers_GetCustomerSelectorByIds([this.primaryCustomerId]).subscribe(customers => {
          for (const customer of customers) {
            this.userCustomers.push({
              customer: customer,
              isPrimary: true,
              isCreateLoad: true,
              isViewLoad: true,
              isBillLoad: true,
              hasNetSuiteId: customer.externalCustomerId ? true : false,
            });
          }
          this.emitCustomerIds();
        });
      }
    } else {
      const existingPrimaryCustomer = this.userCustomers.find(x => x.isPrimary);
      if (existingPrimaryCustomer) {
        existingPrimaryCustomer.isPrimary = false;
      }
    }
  }

  private gelAllCustomerIds() {
    var customerIds = [];

    if (this.primaryCustomerId) {
      customerIds.push(this.primaryCustomerId);
    }

    if (this.viewCustomerIds && this.viewCustomerIds.length > 0) {
      customerIds = Array.from(new Set(customerIds.concat(this.viewCustomerIds)));
    }

    if (this.createCustomerIds && this.createCustomerIds.length > 0) {
      customerIds = Array.from(new Set(customerIds.concat(this.createCustomerIds)));
    }

    if (this.billCustomerIds && this.billCustomerIds.length > 0) {
      customerIds = Array.from(new Set(customerIds.concat(this.billCustomerIds)));
    }

    return customerIds;
  }

  private isCustomerHasViewAccess(customerId: number | undefined) {
    return customerId && this.viewCustomerIds ? this.viewCustomerIds?.includes(customerId) : false;
  }

  private isCustomerHasCreateAccess(customerId: number | undefined) {
    return customerId && this.createCustomerIds ? this.createCustomerIds?.includes(customerId) : false;
  }

  private isCustomerHasBillAccess(customerId: number | undefined) {
    return customerId && this.billCustomerIds ? this.billCustomerIds?.includes(customerId) : false;
  }

  private isCustomerPrimary(customerId: number | undefined) {
    return this.primaryCustomerId === customerId;
  }

  private sortUserCustomers() {
    // sort usercustomers by name
    this._userCustomers.sort((a, b) => {
      if (a.customer?.name && b.customer?.name) {
        return a.customer.name.localeCompare(b.customer.name);
      }
      return 0;
    });

    const objectToMove = this._userCustomers.find(s => s.isPrimary);
    if (!objectToMove) {
      return;
    }

    const index = this._userCustomers.indexOf(objectToMove);

    if (index > -1) {
      // Remove the object from its current position
      this._userCustomers.splice(index, 1);

      // Add the object to the top of the list
      this._userCustomers.unshift(objectToMove);
    }
  }
}

class UserCustomerViewModel {
  customer: CustomerSelectorDto | undefined;
  isPrimary: boolean = false;
  isCreateLoad: boolean = false;
  isViewLoad: boolean = false;
  isBillLoad: boolean = false;
  hasNetSuiteId: boolean = false;
}
