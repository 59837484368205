<div class="flex flex-grow bg-white overflow-auto mb-2">
  <table class="w-full text-sm text-left text-gray-500 border">
    <thead class="text-xs text-gray-500 uppercase bg-gray-100">
      <tr>
        <th scope="col" class="px-3 py-3">
          Customer
        </th>
        <th scope="col" class="px-3 py-3">
          Primary
        </th>
        <th scope="col" class="px-3 py-3">
          Create Loads
        </th>
        <th scope="col" class="px-3 py-3">
          View Loads
        </th>
        <th scope="col" class="px-3 py-3">
          Bill Loads
        </th>
        <th scope="col" class="px-3 py-3">
        </th>
      </tr>
    </thead>
    <tbody>
      @for (userCustomer of userCustomers; track userCustomer) {
        <tr class="bg-white border-b">
          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
            {{ userCustomer.customer?.name }}
          </th>
          <td class="px-6 py-4 text-center">
            <input (click)="userCustomerPrimaryToggle(userCustomer)" type="checkbox" [(ngModel)]="userCustomer.isPrimary" [disabled]="userCustomer.isPrimary">
          </td>
          <td class="px-6 py-4 text-center">
            <input (click)="userCustomerCreateLoadToggle(userCustomer)" type="checkbox" [(ngModel)]="userCustomer.isCreateLoad">
          </td>
          <td class="px-6 py-4 text-center">
            <input (click)="userCustomerViewLoadToggle(userCustomer)" type="checkbox" [(ngModel)]="userCustomer.isViewLoad">
          </td>
          <td class="px-6 py-4 text-center">
            <input (click)="userCustomerBillLoadToggle(userCustomer)" type="checkbox" [(ngModel)]="userCustomer.isBillLoad" [disabled]="!userCustomer.isCreateLoad">
            @if (!userCustomer.hasNetSuiteId && userCustomer.isBillLoad) {
              <i class="ml-2 fas fa-exclamation-triangle cursor-pointer" matTooltip="No NetSuite ID"></i>
            }
          </td>
          <td class="px-6 py-4">
            @if (!userCustomer.isPrimary) {
              <i class="fa fa-trash hover:cursor hover:text-red-600 cursor-pointer text-red-400" (click)="removeCustomer(userCustomer)"></i>
            }
          </td>
        </tr>
      }
      @if (!userCustomers || (userCustomers && userCustomers.length <= 0)) {
        <tr class="bg-white border-b">
          <td colspan="5" class="row-span-full px-6 py-4 font-medium text-gray-600 whitespace-nowrap text-center">
            <i>No Customers</i>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>
<button (click)="showAddCustomerModal()" mat-raised-button color="primary" class="w-full"> Add Customer </button>
