import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { CustomerSelectorDto } from './../../../../../core/services/api.service';
import { MatButtonModule } from '@angular/material/button';
import { CustomerSearchComponent } from '../../../../../shared/widgets/customer-search/customer-search.component';

@Component({
    selector: 'sn-user-add-customer-modal',
    templateUrl: './user-add-customer-modal.component.html',
    styleUrls: ['./user-add-customer-modal.component.scss'],
    imports: [MatDialogModule, CustomerSearchComponent, MatButtonModule]
})
export class UserAddCustomerModalComponent implements OnInit {
  dialogRef = inject<MatDialogRef<UserAddCustomerModalComponent>>(MatDialogRef);


  selectedCustomer: CustomerSelectorDto | undefined = undefined;

  ngOnInit(): void {
  }

  customerSelected(customer: CustomerSelectorDto | undefined) {
    this.selectedCustomer = customer;
  }

  addCustomer() {
    this.dialogRef.close(this.selectedCustomer);
  }
}
