import { RequestService } from './../../../core/services/request.service';
import { Component, OnInit, HostListener, ChangeDetectorRef, inject, viewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { environment } from '../../../../environments/environment';
import { UsersClient, UserSettingClient, CustomerSelectorDto, Auth0User, PermissionDto, AppMetadata, RoleDto, Permission, UserInfoDto, LocationContactDto, LocationsClient } from '../../../core/services/api.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { SpinnerService } from '../../../core/spinner/spinner.svc';
import { ConfirmDialogComponent } from '../../../shared/confirm-dialog/confirm-dialog.component';
import { LocationSelectorDto } from '../../../shared/widgets/location-selector/location-selector.component';
import { Permissions } from '../../../shared/common/permissions';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { lastValueFrom } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CustomerSearchComponent } from 'app/shared/widgets/customer-search/customer-search.component';
import { LocationDisplayComponent } from 'app/shared/widgets/location-display/location-display.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserCustomersComponent } from './user-customers/user-customers.component';
import { MatButtonModule } from '@angular/material/button';
import { PermissionCheckerService } from '../../../core/services/permission-checker.service';

@Component({
    selector: 'user-modal',
    templateUrl: './user-modal.component.html',
    styleUrls: ['./user-modal.component.scss'],
    imports: [MatDialogModule, MatTabsModule, FormsModule, MatFormFieldModule, MatInputModule, CustomerSearchComponent, LocationDisplayComponent, MatSelectModule, MatOptionModule, MatIconModule, MatSlideToggleModule, MatTooltipModule, UserCustomersComponent, MatButtonModule]
})
  export class UserModalComponent implements OnInit {
    dialogRef = inject<MatDialogRef<UserModalComponent>>(MatDialogRef);
    private _usersClient = inject(UsersClient);
    private _spinnerService = inject(SpinnerService);
    private _snackBarService = inject(SnackBarService);
    private _userSettingsClient = inject(UserSettingClient);
    private _locationsClient = inject(LocationsClient);
    private _request = inject(RequestService);
    protected _matDialog = inject(MatDialog);
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _permissionCheckerService = inject(PermissionCheckerService);
    data = inject(MAT_DIALOG_DATA);

    readonly tabGroup = viewChild<MatTabGroup>('tabGroup');

    model: Auth0User | undefined;
    errorMessage: string = "";
    selectedCustomer: CustomerSelectorDto | undefined;
    isProduction: boolean = false;
    permissions: Permission[] = [];
    allPermissions: Permission[] = [];
    permissionCategories: { category: string, permissions: Permission[] }[] = [];
    roles: RoleDto[] = [];
    selectedRoles: RoleDto[] = [];
    private selectedPermissions: string[] = [];
    isPermissionSaving = false;
    tabGroupIndex = 0;
    rolePermissionsToggle: {roleId: string, isShow: boolean}[] = [];
    isUserCustomerHasChanges = false;
    hasChanges = false;
    defaultOriginLocationContact: LocationContactDto | undefined;
    isDev: boolean = false;

    constructor() {
        this.isProduction = environment.production;
      }

    @HostListener('window:keyup.esc') onKeyUp() {
      this.dialogRef.close();
    }

    async ngOnInit() {
      await this.loadRoles();

      if (this.data.userId || this.data.email) {
        this.loadUser();
        this.loadUserPermissions();
        this.loadUserRoles();
      } else {
        this.model = new Auth0User();
        this.model.appMetadata = new AppMetadata();
        this.model.userSettings = new UserInfoDto();
        this.loadPermissions();

        if (this.data.customerId) {
          this.model.userSettings.customerId = this.data.customerId;
        }
      }

      this.isDev = await this._permissionCheckerService.hasPermission(Permissions.DEV_ALL);
    }

    onCancelClick(): void {
      this.dialogRef.close();
    }

    private async loadRoles() {
      this._spinnerService.show();
      this.roles = await lastValueFrom(this._usersClient.users_GetAllRoles())
      this.rolePermissionsToggle = this.roles.map(s => { return { roleId: s.id ?? "", isShow: false} });
      this._spinnerService.hide();
    }

    isSelectedRole(role: RoleDto) {
      return this.selectedRoles.filter(s => s.id == role.id).length > 0;
    }

    saveUserToRole($event: MatSlideToggleChange, role:RoleDto){
      if(this.model?.userId) {
        if ($event.checked) {
          this._request.save(this._usersClient.users_AddUserRole(this.model.userId, role.id), () => {
            this.selectedRoles.push(role);
            this.loadUserPermissions();
          }, "Role")
        } else {
          this._request.save(this._usersClient.users_RemoveUserRole(this.model.userId, role.id), () => {
            this.selectedRoles = this.selectedRoles.filter(s => s.id != role.id);
            this.loadUserPermissions();
          }, "Role")
        }
      }
    }

    isPermissionInSelectedRole(permission: Permission) {
      var isInRole = false;
      if (!this.selectedRoles || !this.selectedRoles?.length) {
        return false;
      }

      for(var i = 0; i < this.selectedRoles.length; i++) {
        if ((this.selectedRoles[i]?.permissions ?? []).filter(s => s.value == permission.key).length > 0) {
          isInRole = true;
        }
      }

      return isInRole;
    }

    getDisabledPermissionTooltip(permission: Permission) {
      if (!this.roles || !this.roles?.length) {
        return "";
      }

      for(var i = 0; i < this.roles.length; i++) {
        if ((this.roles[i].permissions ?? []).filter(s => s.value == permission.key).length > 0) {
          return `Permission assigned by Role ${this.roles[i].name}`;
        }
      }

      return "";
    }

    getPermissionsByKeys(permissions: PermissionDto[]) {
      if (!permissions || permissions.length <= 0)
      {
        return;
      }

      let permissionTitleDescriptions: { title: string, description: string }[] = [];
      permissions.forEach(s => {
        permissionTitleDescriptions.push({ title: this.getPermissionTitle(s.value ?? "") ?? "", description: this.getPermissionDescription(s.value ?? "") ?? "" });
      })

      return permissionTitleDescriptions.sort((a, b) => a.title > b.title ? 1 : -1);
    }

    getPermissionTitle(key: string) {
      return this.allPermissions.find(s => s.key == key)?.title;
    }

    getPermissionDescription(key: string) {
      return this.allPermissions.find(s => s.key == key)?.description;
    }

    toggleExpandStatusRolePermission(roleId: string) {
      var rolePermissionToggle = this.rolePermissionsToggle.find(s => s.roleId == roleId);
      if(rolePermissionToggle) {
        rolePermissionToggle.isShow = !rolePermissionToggle.isShow;
      }
    }

    getExpandStatusRolePermission(roleId: string) : boolean {
      return this.rolePermissionsToggle?.find(s => s.roleId == roleId)?.isShow ?? false;
    }

    private loadUserRoles() {
      this._usersClient.users_GetUserRoles(this.data.userId).subscribe((userRoles: RoleDto[]) => {
        this.selectedRoles = this.roles.filter(s => userRoles.findIndex(x => x.id == s.id) > -1);
      })
    }

    private loadPermissions(){
      this._usersClient.users_GetPermissions().subscribe(permissions => {
        this.permissions = permissions;
        this.allPermissions = permissions;
        this.permissions = this.permissions.filter(permission => permission.key != Permissions.DEV_ALL);
        
        if(!this.model?.isASM) {
          const asmOnlyPermissions = [Permissions.ADMIN_ALL, Permissions.SHIPMENTS_CHECKCALLS];
          this.permissions = this.permissions.filter(permission => !!permission.key && !asmOnlyPermissions.includes(permission.key));
        }

        // set category and permission for UI display
        let category: any = [];
        this.permissions.map((s) => {
          if (category.filter((x: any) => x == s.category).length > 0) {
            return;
          }
          category.push(s.category);
          this.permissionCategories.push({ category: s.category ?? "", permissions: this.permissions.filter(x => x.category == s.category)})
        });
      })
    }

    private loadUserPermissions(){
      this._usersClient.users_GetUserPermissions(this.data.userId).subscribe(permissions => {
        this.selectedPermissions = permissions.map(s => s.value ?? "");
      })
    }

    private loadUser() {
      this._spinnerService.show();

      this._usersClient.users_GetUser(this.data.userId).subscribe(user => {
        this.model = user;
        this.setDefaultOriginLocationContact();
        this.loadPermissions();
      },
      error => {
        this._snackBarService.openError('Error loading user: ' + error.message);
      })
      .add(() => this._spinnerService.hide());
    }

    private nextTab() {
      const tabGroup = this.tabGroup();
      if(tabGroup) {
        tabGroup.selectedIndex = this.tabGroupIndex + 1;

      }
    }

    private setDefaultOriginLocationContact() {
      if (this.model && this.model.userSettings && this.model.userSettings.defaultOriginLocationContactId) {
        this._locationsClient.locations_GetLocationContact(this.model.userSettings.defaultOriginLocationContactId).subscribe((locationContact: LocationContactDto) => {
          this.defaultOriginLocationContact = locationContact;
        })
      }
    }

    saveUser() {
      if (this.model?.userId) {
        this.updateUser();
      } else {
        this.createUser();
      }
    }

    deleteUser() {
      const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
        width: '350px',
        data: { title: "Delete User?", message: `Are you sure you want to delete user <b>${this.model?.email}</b>`, displayMessageAsHTML: true, cancelButtonText: "Cancel", okButtonText: "Yes, delete", okButtonColor: 'warn' },
        disableClose: true
      });

      confirmDialogRef.afterClosed().subscribe(result => {
        if (result && this.model && this.model.userId ) {
          this._spinnerService.show();
          this._usersClient.users_DeleteUser(this.model.userId, this.model.email).subscribe(x => {
            this._spinnerService.hide();
            this._snackBarService.open(`User ${this.model?.fullName ?? ""} removed`);
            this.dialogRef.close(true);
          }, err => {
            this._spinnerService.hide();
            this._snackBarService.openError("Error deleting user")
          })
        }
      });
    }

    createUser() {
      if(this.model) {
        this._spinnerService.show();

        if(this.model.userSettings) {
          this.model.userSettings.email = this.model.email ?? "";
        }

        if (this.model.userSettings?.customerId) {
          if (!this.model.appMetadata) {
            this.model.appMetadata = new AppMetadata();
          }
          this.model.appMetadata.viewCustomerIds = [this.model.userSettings.customerId];
          this.model.appMetadata.createCustomerIds = [this.model.userSettings.customerId];
          this.model.appMetadata.billCustomerIds = [this.model.userSettings.customerId];
        }

        this._usersClient.users_PostUser(this.model).subscribe((createdUser: Auth0User) => {
          if(this.model) {
            this._snackBarService.open('User ' + createdUser.fullName + ' added successfully');
            this.model.userId = createdUser.userId;
            this.nextTab();
          }
        },
        error => {
          if (error.response.includes("password")) {
            this.errorMessage = "The specified password does not comply with password complexity requirements. Please provide a different password."
          } else {
            this.errorMessage = "An error occurred: " + error.message;
          }
        })
        .add(() => this._spinnerService.hide());
      }
    }

    updateUser() {
      if(this.model) {
        this._spinnerService.show();

        this._usersClient.users_UpdateUser(this.model)
          .subscribe(x => {
            this._snackBarService.open('User ' + this.model?.fullName + ' saved successfully');
            this.dialogRef.close(true);
          },
          error => {
            this._snackBarService.open('Error saving user: ' + error.message);
          })
          .add(() => this._spinnerService.hide());
      }
    }

    customerSelected(customer: CustomerSelectorDto) {
      if(this.model?.userSettings) {
        if(customer) {
          this.model.userSettings.customerId = customer.id;
        }
        else {
          this.model.userSettings.customerId = 0;
        }
      }
      this.hasChanges = true;
    }

    customersSelected(customers:any) {
      if (this.model && this.model.appMetadata) {
        this.model.appMetadata.viewCustomerIds = customers.viewCustomerIds;
        this.model.appMetadata.createCustomerIds = customers.createCustomerIds;
        this.model.appMetadata.billCustomerIds = customers.billCustomerIds;
      }

      if (customers.primaryCustomerId && this.model && this.model.userSettings) {
        // removed default origin location contact if primary customer is changed
        if (customers.primaryCustomerId != this.model.userSettings.customerId && this.model.userSettings.defaultOriginLocationContact) {
          this.model.userSettings.defaultOriginLocationContact = undefined;
        }

        this.model.userSettings.customerId = customers.primaryCustomerId;
      }
      else if (!customers.primaryCustomerId && this.model && this.model.userSettings) {
        this.model.userSettings.customerId = 0;
      }

      this.isUserCustomerHasChanges = customers.hasChanges;
      this.hasChanges = customers.hasChanges;
    }

    defaultOriginSelected(selection: LocationSelectorDto) {
      // Check if previous value is different from current value
      if (this.model?.userSettings?.defaultOriginLocationContactId !== selection?.locationContact?.id) {
        this.hasChanges = true;
      }

      if(this.model?.userSettings) {
        if(selection?.locationContact) {
          this.model.userSettings.defaultOriginLocationContactId = selection.locationContact.id;
        }
        else {
          delete this.model.userSettings.defaultOriginLocationContactId;
          delete this.model.userSettings.defaultOriginLocationContact;
        }
      }
    }

    hasPermission(permissionName: string) {
      return this.selectedPermissions.some(x => x == permissionName)
    }

    addRemovedPermissions(isCheck: boolean, permissionName: string) {
      if(this.model?.userId) {
        this.isPermissionSaving = true;
        if (isCheck) {
          return this._usersClient.users_AddUserPermission(this.model.userId, permissionName).subscribe(result => {
            this.selectedPermissions.push(permissionName);
          }, (error) => {
            this.isPermissionSaving = false;
          }, () => {
            this.isPermissionSaving = false;
          })
        } else {
          return this._usersClient.users_RemoveUserPermission(this.model.userId, permissionName).subscribe(result => {
            this.selectedPermissions = this.selectedPermissions.filter(s => {
              return s != permissionName;
            });
          }, (error) => {
            this.isPermissionSaving = false;
          }, () => {
            this.isPermissionSaving = false;
          })
        }
      }
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
      this.tabGroupIndex = tabChangeEvent.index;
    }

    blockUser() {
      // show confirmation dialog to block user
      const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
        width: '350px',
        data: { title: "Block User?", message: `Are you sure you want to block user <b>${this.model?.email}</b>`, displayMessageAsHTML: true, cancelButtonText: "Cancel", okButtonText: "Block", okButtonColor: 'warn' },
        disableClose: true
      });

      // if user confirms, block user
      confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._request.save(this._usersClient.users_BlockUser(this.model.userId), () => {
            this._snackBarService.open(`User ${this.model?.fullName ?? ""} blocked`);
            this.dialogRef.close(true);
          }, "User")
        }
      });
    }

    unblockUser() {
      if (this.model.blocked) {
        // user is blocked, show confirmation dialog to unblock user
        const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
          width: '350px',
          data: { title: "Unblock User?", message: `Are you sure you want to unblock user <b>${this.model?.email}</b>`, displayMessageAsHTML: true, cancelButtonText: "Cancel", okButtonText: "Unblock", okButtonColor: 'warn' },
          disableClose: true
        });

        // if user confirms, unblock user
        confirmDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._request.save(this._usersClient.users_UnblockUser(this.model.userId), () => {
              this._snackBarService.open(`User ${this.model?.fullName ?? ""} unblocked`);
              this.dialogRef.close(true);
            }, "User")
          }
        });
      }
    }
}
