<h4 mat-dialog-title>
  <div class="flex justify-between align-middle">
    <div>
      <div class="inline-flex align-middle">
        {{!model?.userId ? "Add" : "Edit"}} User
      </div>
      @if (model?.blocked) {
        <div class="inline-flex items-center px-3 my-1 xl:my-0 py-0.5 rounded-full text-sm font-medium bg-red-700 text-white ml-2">
          Blocked
        </div>
      }
    </div>
    <a href="https://support.asmgroupinc.com/a/solutions/articles/150000164806" class="outline-none" target="_blank" matTooltip="Read Documentation">
      <mat-icon class="w-5 h-5 text-gray-500 hover:text-blue-500 cursor-pointer" svgIcon="heroicons_outline:question-mark-circle"></mat-icon>
    </a>
  </div>
</h4>
<mat-dialog-content>
  @if (errorMessage) {
    <p class="warning">
      {{errorMessage}}
    </p>
  }
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" #tabGroup (selectedTabChange)="tabChanged($event)" [disablePagination]="true">
    <mat-tab label="Details">
      <form #userForm="ngForm" autocomplete="off" class="pt-2 w-[640px]">
        @if (model) {
          @if (!isProduction) {
            <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-2 mb-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: solid/exclamation -->
                  <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <p class="text-sm text-yellow-700">
                      <b>Warning:</b> Users are shared with production, so any edits here apply to production users. Do not
                      edit users in Test Environment.
                    </p>
                  </div>
                </div>
              </div>
            }
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput name="fullName" [(ngModel)]="model.fullName" required>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="model.email" name="email" required [disabled]="model?.userId">
            </mat-form-field>
            @if (!model.userId && model.email && model.email.toLocaleLowerCase().includes('@asmgroupinc.com')) {
              <p class="warning">
                Do not add ASM users.
              </p>
            }
            @if (!model.userId) {
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput [(ngModel)]="model.password" name="password" required>
                <mat-hint>Requires Upper, Lower, Number and Special Character</mat-hint>
              </mat-form-field>
            }
            @if (!model?.userId) {
              <sn-customer-search #customerSelect [customerID]="model.userSettings?.customerId"
                hint="Primary customer which this user is a member of" (customer)="customerSelected($event);"
              [requireContract]="false" label="Customer"></sn-customer-search>
            }
            <input [ngModel]="model.userSettings?.customerId" name="customerId_hidden" required type="hidden">
            @if (model.userSettings?.customerId) {
              <div>
                <sn-location-display #originLocation [inputContact]="defaultOriginLocationContact"
                  [displayContact]="true" [locationID]="defaultOriginLocationContact?.locationID"
                  (locationSelected)="defaultOriginSelected($event);" [customerID]="model.userSettings?.customerId"
                label="Default Origin Location Contact"></sn-location-display>
                <mat-form-field>
                  <mat-label>Default Direction</mat-label>
                  <mat-select [(ngModel)]="model?.userSettings.defaultDirection" name="defaultDirection">
                    <mat-option value="None">None</mat-option>
                    <mat-option value="Inbound">Inbound</mat-option>
                    <mat-option value="Outbound">Outbound</mat-option>
                    <mat-option value="DropShipment">Drop Shipment</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            }
          }
        </form>
      </mat-tab>
      <mat-tab [disabled]="!model?.userId" label="Roles">
        <div class="grid grid-cols-1 gap-3 overflow-hidden">
          @for (role of roles; track role) {
            <fieldset>
              <div class="divide-y divide-gray-200">
                <div class="relative flex items-start py-2">
                  <div class="min-w-0 flex-1 text-sm">
                    <h3 class="text-xl leading-7 text-gray-600">{{ role.name }}</h3>
                    <p class="text-gray-500">{{ role.description }}</p>
                  </div>
                  @if (role.permissions?.length) {
                    <div class="ml-3 flex items-center">
                      @if (!getExpandStatusRolePermission(role.id)) {
                        <mat-icon class="hover:cursor-pointer" (click)="toggleExpandStatusRolePermission(role.id)">expand_more</mat-icon>
                      }
                      @if (getExpandStatusRolePermission(role.id)) {
                        <mat-icon class="hover:cursor-pointer" (click)="toggleExpandStatusRolePermission(role.id)">expand_less</mat-icon>
                      }
                    </div>
                  }
                  <div class="ml-3 flex h-5 items-center">
                    <mat-slide-toggle color="primary" hideIcon="true" (change)="saveUserToRole($event,role)" [checked]="isSelectedRole(role)"></mat-slide-toggle>
                  </div>
                </div>
              </div>
              @if (getExpandStatusRolePermission(role.id)) {
                <div class="divide-y divide-gray-200">
                  @for (permission of getPermissionsByKeys(role.permissions); track permission) {
                    <div class="relative flex items-start py-2">
                      <div class="min-w-0 flex-1 text-sm">
                        <label for="comments" class="font-medium text-gray-700">{{ permission.title }}</label>
                        <p id="comments-description" class="text-gray-500">{{ permission.description }}</p>
                      </div>
                    </div>
                  }
                </div>
              }
            </fieldset>
          }
        </div>
      </mat-tab>
      <mat-tab [disabled]="!model?.userId" label="Permissions">
        <div class="grid grid-cols-1 gap-3 overflow-hidden mt-2">
          @for (permissionCategory of permissionCategories; track permissionCategory) {
            @if (permissionCategory.permissions?.length > 0)
            {
              <fieldset>
                <h2 class="text-xl leading-7 text-gray-600">{{ permissionCategory.category }}</h2>
                <div class="divide-y divide-gray-200">
                  @for (permission of permissionCategory.permissions; track permission) {
                    <div class="relative flex items-start py-2">
                      <div class="min-w-0 flex-1 text-sm">
                        <label for="comments" class="font-medium text-gray-700">{{ permission.title }}</label>
                        <p id="comments-description" class="text-gray-500">{{ permission.description }}</p>
                      </div>
                      <div class="ml-3 flex h-5 items-center">
                        <mat-slide-toggle color="primary" hideIcon="true" [disabled]="isPermissionSaving || isPermissionInSelectedRole(permission) || (permission.isDevOnly && !this.isDev)"
                          [checked]="hasPermission(permission.key)"
                          [matTooltip]="isPermissionInSelectedRole(permission) ? getDisabledPermissionTooltip(permission): null"
                        (change)="addRemovedPermissions($event.checked, permission.key)"></mat-slide-toggle>
                      </div>
                    </div>
                  }
                </div>
              </fieldset>
            }
          }
        </div>
      </mat-tab>
      <mat-tab [disabled]="!model?.userId" label="Customers">
        <div class="rounded-md bg-blue-50 p-4 my-2">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/information-circle -->
              <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-blue-700">Manage which customers this user has access to and what actions they can perform</p>
              </div>
            </div>
          </div>
          @if (model?.appMetadata?.billCustomerIds?.length === 0) {
            <div class="rounded-md bg-red-200 text-gray-800 p-4 my-2">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <i class="fas fa-fw fa-exclamation-triangle"></i>
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                  <p class="text-sm ">Must select at least 1 customer to Bill Loads</p>
                </div>
              </div>
            </div>
          }
          <div class="pt-2 overflow-hidden">
            @if (model?.userId) {
              <sn-user-customers
                [primaryCustomerId]="model?.appMetadata?.customerId"
                [viewCustomerIds]="model?.appMetadata?.viewCustomerIds"
                [billCustomerIds]="model?.appMetadata?.billCustomerIds"
                [createCustomerIds]="model?.appMetadata?.createCustomerIds"
                (customers)="customersSelected($event)"
              ></sn-user-customers>
            }
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="dialog-footer-buttons" (click)="onCancelClick()">Cancel</button>
      @if (model?.blocked) {
        <button mat-raised-button color="warn" class="dialog-footer-buttons"
        (click)="unblockUser()">Unblock</button>
      }
      @if (!model?.blocked && tabGroupIndex == 0 && model?.userId) {
        <button mat-raised-button color="warn" class="dialog-footer-buttons"
        (click)="blockUser()">Block</button>
      }
      @if (isDev && tabGroupIndex == 0 && model?.userId) {
        <button mat-raised-button color="warn" class="dialog-footer-buttons"
        (click)="deleteUser()">Delete</button>
      }
      @if (tabGroupIndex != 1 && tabGroupIndex != 2) {
        <button mat-raised-button color="primary" class="dialog-footer-buttons" (click)="saveUser()"
        [disabled]="(tabGroupIndex == 0 && (!userForm.valid || (!userForm.dirty && !hasChanges))) || (tabGroupIndex == 3 && !isUserCustomerHasChanges) || model?.appMetadata?.billCustomerIds?.length === 0">Save</button>
      }
    </mat-dialog-actions>
    